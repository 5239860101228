import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import step2compliance from "../../Assets/Projects/step2compliance.png";
import condobridge from "../../Assets/Projects/condobridge.png";
import radiobridge from "../../Assets/Projects/radiobridge.png";
import vevacollect from "../../Assets/Projects/vevacollect.png";
import landlordcerts from "../../Assets/Projects/landlordcerts.png";
import prescriptionhope from "../../Assets/Projects/prescriptionhope.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={vevacollect}
              isBlog={false}
              title="Veva Collect"
              description="Developed APIs for retrieving data from S3 and deleting files. Created Zip Files using SQS and Zipworker (Go), and wrote GraphQL queries and mutations. Created jobs and email templates using MJML. Worked on front-end (React JS), back-end (Laravel), ZipWorker (GoLang), and GraphQL."
              // ghLink="https://github.com/soumyajit4419/Chatify"
              demoLink="https://vevacollect.com/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={prescriptionhope}
              isBlog={false}
              title="Prescription Hope"
              description="Developed a comprehensive patient portal for medication applications and monthly invoice payments. Created an agent portal and an admin panel using PHP CodeIgniter, ensuring efficient performance and scalability."
              // ghLink="https://github.com/soumyajit4419/Bits-0f-C0de"
              demoLink="https://prescriptionhope.com/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={radiobridge}
              isBlog={false}
              title="Radio Bridge"
              description="Added features to the Radio Bridge Console for the IoT industry using the LoRaWAN wireless standard. Developed graphs for device event analytics, new network server integration, and groups. Worked on both front-end (Vue JS) and back-end (Laravel)."
              // ghLink="https://github.com/soumyajit4419/Editor.io"
              demoLink="https://radiobridge.com/"              
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={step2compliance}
              isBlog={false}
              title="Step to Compliance"
              description="Crafted a Lambda function to read messages from SQS, perform calculations, and invoke a Laravel webhook. Developed a Python script to receive data from a Laravel website, generate an xlsm file, and send it back to the site. Worked on both front-end (Vue JS) and back-end (Laravel)."
              // ghLink="https://github.com/soumyajit4419/Plant_AI"
              demoLink="https://www.step2compliance.com/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={landlordcerts}
              isBlog={false}
              title="Landlord Certs London"
              description="Designed the project flow and database architecture. Completely developed the entire system, including graphs, online payments, and a booking system. Managed a development team to ensure successful project completion using Laravel."
              // ghLink="https://github.com/soumyajit4419/AI_For_Social_Good"
              demoLink="https://landlordcertslondon.co.uk/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={condobridge}
              isBlog={false}
              title="Condo Bridge"
              description="Developed multi-modules for an inner building management system. Integrated payments (Stripe, PayPal) and communication features (Twilio SMS & Video Calls)."
              // ghLink="https://github.com/soumyajit4419/Face_And_Emotion_Detection"
              demoLink="https://condobridge.com/" 
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
